import * as React from "react";
import { observer } from "mobx-react";
import { TravelAgentAuthenticationOptionsProps } from "./typings";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { AuthenticationOptions } from "@shared-ui/retail-travel-agent-authentication-options";

export const TravelAgentAuthenticationOptions: React.FC<TravelAgentAuthenticationOptionsProps> = observer(
  ({ templateComponent }) => {
    const {
      metadata: { id },
    } = templateComponent;

    return (
      <div id={id} data-testid="authentication-options">
        <UitkLayoutGrid columns={{ small: ["1fr"], medium: ["120x"] }} justifyContent="center" alignItems="center">
          <UitkLayoutGridItem>
            <AuthenticationOptions />
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      </div>
    );
  }
);

export default TravelAgentAuthenticationOptions;
